import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUploadUserAvatarMutation
} from "features/userManagement/userManagementApiSlice";
import {
  getDataEntityItemById,
  getFloorsByBuildingOptions,
  getFormattedDate,
  getWorkstationsOptions
} from "shared/lib/getters";
import { EMPLOYEE_STATUS } from "shared/constants";
import EmptyUserImage from "shared/assets/images/empy-user.svg";
import {
  selectBuildingsList,
  selectFilteredUserManagementList,
  selectDepartmentsList,
  selectMappedNeighborhoodsList,
  selectSites,
  selectUsersCityList,
  selectUsersByBuildingOptions,
  selectUsersTitleList,
  selectSiteFilter,
  selectFloorsByBuilding,
  selectAccessToken
} from "core/selectors";
import { setFormValues } from "shared/lib/setFormValues";
import { isEmptyObject, isValidAndNotEmptyString, isValidArray } from "shared/lib/validation";
import { fileToBase64 } from "shared/lib";
import { useWorkstationsByBuildingsQuery } from "features/workstations/workstationApiSlice";

const defaultValues = {
  image: "",
  firstName: "",
  lastName: "",
  email: "",
  gender: null,
  dateOfBirth: "",
  phone: "",
  employmentDate: "",
  title: "",
  site: "",
  building: "",
  floor: 0,
  department: "",
  officeDays: 1,
  workstationId: "",
  neighborhood: "",
  localDisplayName: "",
  isSuspended: false,
  entranceId: null,
  timeWatchId: null
};

const mapStateToProps = (state) => ({
  userManagementList: selectFilteredUserManagementList(state),
  usersByBuildingOptions: selectUsersByBuildingOptions(state),
  floorsByBuilding: selectFloorsByBuilding(state),
  buildingList: selectBuildingsList(state),
  siteList: selectSites(state),
  site: selectSiteFilter(state),
  neighborhoodList: selectMappedNeighborhoodsList(state),
  departmentList: selectDepartmentsList(state),
  titleList: selectUsersTitleList(state),
  cityList: selectUsersCityList(state),
  token: selectAccessToken(state)
});

export default function useUserManagementPageLogic({ isEdit, isOpen, userId }) {
  /* ------------------ HOOKs ------------------ */

  const {
    handleSubmit,
    control,
    reset,
    register,
    setValue,
    watch,
    formState: { errors, isDirty: isFormDirty }
  } = useForm({
    mode: "onChange",
    defaultValues: { ...defaultValues, floor: 0 }
  });

  /* ------------------ STATEs ------------------ */

  const [assignedUserName, setAssignedUserName] = useState("");
  const [showHelpText, setShowHelpText] = useState(true);
  const [floorAccordance, setFloorAccordance] = useState(true);
  const [showDeleteDialog, setShowDialog] = useState(false);
  const [deleteUserErrorMessage, setDeleteUserErrorMessage] = useState(false);

  const {
    userManagementList,
    usersByBuildingOptions,
    floorsByBuilding,
    buildingList,
    siteList,
    neighborhoodList,
    departmentList,
    titleList,
    cityList,
    site,
    token
  } = useSelector(mapStateToProps);

  const [image, firstName, lastName, workstationId, floor, building] = watch([
    "image",
    "firstName",
    "lastName",
    "workstationId",
    "floor",
    "building"
  ]);

  /* ------------------ APIs ------------------ */

  const { data: workstationByBuildingsData, isLoading: isWorkstationByBuildingsLoading } =
    useWorkstationsByBuildingsQuery(
      { site, building, date: getFormattedDate(new Date(), "yyyy-MM-dd") },
      { skip: !isOpen || !token || !site || !building }
    );

  const [addUser, { isLoading: isAddLoading, error: addError, isSuccess: isSuccessAdded }] = useAddUserMutation();
  const [updateUser, { isLoading: isUpdateLoading, error: updateError, isSuccess: isSuccessUpdated }] =
    useUpdateUserMutation();
  const [
    uploadUserAvatar,
    { isLoading: isUploadUserAvatarLoading, error: uploadUserAvatarError, isSuccess: isSuccessUploadUserAvatar }
  ] = useUploadUserAvatarMutation();
  const [deleteUser, { isLoading: isDeleteLoading, isSuccess: isSuccessDelete }] = useDeleteUserMutation();

  /* ------------------ MEMOs ------------------ */

  const filteredUserList = useMemo(
    () => [{ id: 0, value: "None" }, ...usersByBuildingOptions].filter(({ id }) => id !== userId),
    [usersByBuildingOptions, userId]
  );

  const currentUser = useMemo(
    () => isEdit && getDataEntityItemById(userManagementList, userId),
    [isEdit, userManagementList, userId]
  );

  const floorsOptions = useMemo(
    () => getFloorsByBuildingOptions(floorsByBuilding, building),
    [floorsByBuilding, building]
  );

  const workstationsByBuilding = useMemo(() => {
    if (
      isEmptyObject(workstationByBuildingsData) ||
      isWorkstationByBuildingsLoading ||
      !building ||
      !workstationByBuildingsData[building]?.length
    )
      return [];

    return workstationByBuildingsData[building];
  }, [workstationByBuildingsData, isWorkstationByBuildingsLoading, building]);

  const workstationsByFloorOptions = useMemo(() => {
    const data = workstationsByBuilding.filter((workstation) => parseInt(workstation.floor) === parseInt(floor));

    return getWorkstationsOptions(data);
  }, [workstationsByBuilding, floor]);

  /* ------------------ HANDLERs ------------------ */

  const getImageSrc = () => {
    let src = "";

    if (!image) {
      src = EmptyUserImage;
    } else if (isEdit && isValidAndNotEmptyString(image)) {
      src = image;
    } else if (!isEmptyObject(image)) {
      src = URL.createObjectURL(image[0]);
    }
    return src;
  };

  const handleClearWorkstation = () => {
    setValue("workstationId", "");
  };

  const emailValidate = (value) => {
    const isExist = userManagementList.some((user) => user.email === value && user.email !== currentUser?.email);

    if (!isEdit) {
      if (isExist) {
        return "A user with this email already exists";
      }
    }
  };

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (workstationId && floor) {
      if (parseInt(workstationId.floor) === parseInt(floor)) {
        setFloorAccordance(true);
      } else {
        setFloorAccordance(false);
      }
    }
  }, [workstationId, floor]);

  useEffect(() => {
    if (workstationId?.assignedEmployeeId && userManagementList?.length) {
      const user = usersByBuildingOptions.find((item) => item.id === workstationId?.assignedEmployeeId);

      if (user) {
        setShowHelpText(true);
        setAssignedUserName(user?.value);
      }

      if (isEdit && user.id === currentUser.id) {
        setShowHelpText(false);
      }
    }
  }, [workstationId, usersByBuildingOptions]);

  useEffect(() => {
    if (isEdit && currentUser && !isFormDirty) {
      setFormValues({
        data: {
          ...currentUser,
          isSuspended: currentUser.employeeStatus === EMPLOYEE_STATUS.suspended,
          department:
            isValidArray(currentUser?.department) && currentUser?.department?.length
              ? currentUser?.department.join(", ")
              : currentUser?.department
        },
        setValue,
        usersByBuildingOptions,
        workstationList: workstationsByBuilding,
        titleList,
        cityList,
        neighborhoodList
      });
    }
  }, [currentUser, workstationsByBuilding, usersByBuildingOptions, setValue, titleList, cityList, isEdit, isFormDirty]);

  useEffect(() => {
    if (floorsOptions?.length) {
      const floor = currentUser && currentUser.building === building ? currentUser.floor : floorsOptions[0].option;

      setValue("floor", floor);
    }
  }, [floorsOptions, building, currentUser]);

  /* ------------------ EFFECTs ------------------ */

  const onDeleteUser = () => {
    setShowDialog(true);
  };

  const onDeleteHandler = async () => {
    setShowDialog(false);

    try {
      await deleteUser({ id: currentUser.id }).unwrap();
    } catch (error) {
      const errorMessage =
        error?.status === 400
          ? "The user you are trying to delete has employees reporting to him. Please change the manager definition for all employees reporting to this user and try again."
          : error.data.message;
      setDeleteUserErrorMessage(errorMessage);
    }
  };

  const onRejectDeleteHandler = () => {
    setShowDialog(false);
  };

  const onSubmit = async (values) => {
    const image = await fileToBase64(values?.image);
    const transformedData = {
      ...values,
      dateOfBirth: getFormattedDate(values?.dateOfBirth, "yyyy-MM-dd"),
      employmentDate: getFormattedDate(values?.employmentDate, "yyyy-MM-dd"),
      workstationId: values?.workstationId?.id,
      managerId: values?.managerId?.id,
      phone: values?.phone[0] === "+" ? values.phone : `+${values.phone}`,
      neighborhood: values?.neighborhood?.option,
      department:
        isValidArray(values?.department) && values?.department?.length
          ? values?.department.join(", ")
          : values?.department,
      displayNameLocal: values?.localDisplayName
    };

    delete transformedData.localDisplayName;
    delete transformedData.image;
    delete transformedData.employeeStatus;

    try {
      if (isEdit) {
        delete transformedData.employeeStatus;

        updateUser({ id: userId, params: transformedData });

        if (image?.data) {
          uploadUserAvatar({ id: userId, params: image.data });
        }
      } else {
        const response = await addUser({ ...transformedData, role: "USER" }).unwrap();

        if (image?.data && response?.id) {
          uploadUserAvatar({ id: response.id, params: image.data });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    isLoading: isAddLoading || isUpdateLoading || !floorAccordance || isDeleteLoading || isUploadUserAvatarLoading,
    error: addError || updateError || deleteUserErrorMessage || uploadUserAvatarError,
    isSuccess: isSuccessAdded || isSuccessUpdated || isSuccessDelete || isSuccessUploadUserAvatar,
    currentUser,
    reset,
    image,
    firstName,
    handleSubmit,
    onSubmit,
    lastName,
    getImageSrc,
    register,
    errors,
    control,
    emailValidate,
    titleList,
    filteredUserList,
    departmentList,
    cityList,
    siteList,
    buildingList,
    floorsOptions,
    floorAccordance,
    workstationId,
    workstationsByFloorOptions,
    neighborhoodList,
    assignedUserName,
    floor,
    showHelpText,
    handleClearWorkstation,
    onDeleteUser,
    showDeleteDialog,
    onDeleteHandler,
    onRejectDeleteHandler,
    setShowDialog,
    building
  };
}
