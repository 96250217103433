import { apiSlice } from "core/api/apiSlice";
import { RGX_FILENAME } from "shared/constants";
import { downloadFile } from "shared/lib/fileUtils";

export const attendanceApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    dailyPlan: build.query({
      query: ({ cToken, site, date }) => ({
        url: "/scheduler/plan/dailyPlan",
        method: "GET",
        params: { cToken, site, date }
      })
    }),
    attendance: build.query({
      query: ({ fromDate, toDate, numberOfWeeks, building, departments, floors }) => ({
        url: "/scheduler/plan/attendance",
        method: "GET",
        params: { numberOfWeeks, fromDate, toDate, building, departments, floors }
      })
    }),
    overlaps: build.query({
      query: ({ site, building, floors, departments }) => ({
        url: "/scheduler/plan/overlaps",
        method: "GET",
        params: { sites: site, buildings: building, floors, departments }
      })
    }),
    exportAttendance: build.mutation({
      query: ({ fromDate, toDate, fileType, building, floors, departments }) => ({
        url: `/scheduler/plan/attendance/${fileType}`,
        method: "GET",
        params: { fromDate, toDate, building, floors, departments },
        headers: {
          Accept: `text/${fileType}`
        },
        responseHandler: async (response) => {
          const fileName = RGX_FILENAME.exec(response.headers.get("Content-Disposition"))?.groups?.filename;

          downloadFile(response, fileName);
        },
        cache: "no-cache"
      })
    }),
    exportAvgDaysPerWeek: build.mutation({
      query: ({ fromDate, toDate, fileType, building, floors, departments }) => ({
        url: `/scheduler/plan/attendance/avgDaysPerWeek/${fileType}`,
        method: "GET",
        params: { fromDate, toDate, building, floors, departments },
        headers: {
          Accept: `text/${fileType}`
        },
        responseHandler: async (response) => {
          const fileName = RGX_FILENAME.exec(response.headers.get("Content-Disposition"))?.groups?.filename;

          downloadFile(response, fileName);
        },
        cache: "no-cache"
      })
    }),
    exportAvgEmployeesPerDay: build.mutation({
      query: ({ fromDate, toDate, fileType, building, floors, departments }) => ({
        url: `/scheduler/plan/attendance/avgEmployeesPerDay/${fileType}`,
        method: "GET",
        params: { fromDate, toDate, building, floors, departments },
        headers: {
          Accept: `text/${fileType}`
        },
        responseHandler: async (response) => {
          const fileName = RGX_FILENAME.exec(response.headers.get("Content-Disposition"))?.groups?.filename;

          downloadFile(response, fileName);
        },
        cache: "no-cache"
      })
    }),
    exportDayTypeChanges: build.mutation({
      query: ({ date, fileType, building, floors, departments }) => ({
        url: `/scheduler/plan/${date}/dayTypeChanges/${fileType}`,
        method: "GET",
        params: { building, floors, departments },
        headers: {
          Accept: `text/${fileType}`
        },
        responseHandler: async (response) => {
          const fileName = RGX_FILENAME.exec(response.headers.get("Content-Disposition"))?.groups?.filename;

          downloadFile(response, fileName);
        },
        cache: "no-cache"
      })
    }),
    avgEmployeesPerDay: build.query({
      query: ({ fromDate, toDate, building, floors, departments }) => ({
        url: `/scheduler/plan/attendance/avgEmployeesPerDay`,
        method: "GET",
        params: { fromDate, toDate, building, floors, departments }
      })
    }),
    avgDaysPerWeek: build.query({
      query: ({ fromDate, toDate, building, floors, departments }) => ({
        url: `/scheduler/plan/attendance/avgDaysPerWeek`,
        method: "GET",
        params: { fromDate, toDate, building, floors, departments }
      })
    }),

    updateDailyAssignment: build.mutation({
      query: ({ companyId, employeeId, dayType, seatId = null, site, date, cToken }) => ({
        url: `/scheduler/plan/update`,
        method: "POST",
        body: {
          companyId,
          site,
          employeeId,
          date,
          dayType,
          seatId
        },
        params: { cToken }
      })
    })
  })
});

export const {
  useDailyPlanQuery,
  useAttendanceQuery,
  useOverlapsQuery,
  useExportAttendanceMutation,
  useExportAvgDaysPerWeekMutation,
  useExportAvgEmployeesPerDayMutation,
  useExportDayTypeChangesMutation,
  useAvgEmployeesPerDayQuery,
  useAvgDaysPerWeekQuery,
  useUpdateDailyAssignmentMutation
} = attendanceApiSlice;
