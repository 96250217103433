import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isValidNumber } from "shared/lib/validation";
import { selectCompanyAndSiteSettings, selectUserEmail } from "core/selectors";
import { useConfirmMutation, useLoginMutation } from "features/auth/authApiSlice";
import { setUserInfo, setRole, setNavigationResources } from "features/auth/authSlice";
import {
  STORAGE_USER_INFO,
  ROLE_USER,
  STORAGE_ROLE,
  ROLE_PREFIX,
  STORAGE_NAVIGATION_RESOURCES,
  RESEND_CODE_TYPE
} from "shared/constants";
import jwtDecode from "jwt-decode";
import { getBrowserName } from "shared/lib/getters";
import { useCountdown } from "shared/hooks";
import { useCompanyStylesQuery, useRoleByNameMutation } from "features/adminSettings/adminSettingsApiSlice";
import { setCompanySite } from "features/site/siteSlice";
import { toast } from "react-toastify";

const COUNTDOWN_DURATION = 30 * 1000; // ms

export default function useConfirmationCodePageLogic({ history }) {
  const smsCountdown = useCountdown(COUNTDOWN_DURATION);
  const emailCountdown = useCountdown(COUNTDOWN_DURATION);

  const [code, setCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [logoUrl, setLogoUrl] = useState("");

  const [email, companyAndSiteSettings] = useSelector((state) => [
    selectUserEmail(state),
    selectCompanyAndSiteSettings(state)
  ]);
  const dispatch = useDispatch();

  console.log("companyAndSiteSettings", companyAndSiteSettings);

  const [login] = useLoginMutation();
  const [confirm, { isLoading }] = useConfirmMutation();
  const [getRoleByName] = useRoleByNameMutation();
  const { data: companyStylesData, isLoading: isLoadingCompanyStyle } = useCompanyStylesQuery(
    { email },
    { skip: !email }
  );

  const roleHandler = async (roles) => {
    const role = roles?.length ? roles[0].replace(ROLE_PREFIX, "") : ROLE_USER;

    dispatch(setRole(role));
    localStorage.setItem(STORAGE_ROLE, role);

    try {
      const response = await getRoleByName({ role }).unwrap();

      localStorage.setItem(STORAGE_NAVIGATION_RESOURCES, JSON.stringify(response?.resources));
      dispatch(setNavigationResources(response?.resources));
    } catch (error) {
      console.error("ERROR :: gerRoleInfoHandler ::", { error, role });
    }
  };

  const confirmSuccessHandler = async (data) => {
    if (!data) return;

    const parsed = jwtDecode(data.accessToken);
    const userInfo = {
      email,
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      cToken: parsed.cToken,
      site: parsed.site
    };

    localStorage.setItem(STORAGE_USER_INFO, JSON.stringify(userInfo));
    dispatch(setCompanySite(parsed.site));
    dispatch(setUserInfo(userInfo));

    await roleHandler(parsed?.roles);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setCode(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidNumber(parseInt(code))) {
      setErrorMsg("Incorrect code address");
      return;
    }
    setErrorMsg("");

    try {
      const deviceName = `Desktop ${getBrowserName()}`;
      const data = await confirm({ email, code, deviceName }).unwrap();

      confirmSuccessHandler(data);
    } catch (error) {
      if (error?.data) {
        // TODO: use actual ERROR code. Need changes on BE
        if (error?.data?.message === "User with provided email is not allowed to login!") {
          toast.error("Your user does not have dashboard permissions.", {
            autoClose: 10000
          });
        }

        setErrorMsg(error.data?.message);
      } else {
        setErrorMsg("Something went wrong. Please try again.");
      }
    }
  };

  useEffect(() => {
    if (companyStylesData?.logoUrl) {
      setLogoUrl(companyStylesData.logoUrl);
    }
  }, [companyStylesData, isLoadingCompanyStyle]);

  const onSendSms = () => {
    login({ email, notificationType: RESEND_CODE_TYPE.SMS });

    smsCountdown.on();
  };

  const onSendEmail = () => {
    login({ email, notificationType: RESEND_CODE_TYPE.EMAIL });

    emailCountdown.on();
  };

  return {
    handleSubmit,
    errorMsg,
    handleChange,
    code,
    isLoading,
    onSendSms,
    onSendEmail,
    smsCountdown,
    emailCountdown,
    logoUrl,
    isLoadingCompanyStyle
  };
}
