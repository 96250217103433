import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import DataTable from "shared/components/DataTable/DataTable";
import { getTableStatusColumnColor } from "shared/lib/tableHelpers";
import { WORKSTATION_TABLE_NAME } from "shared/constants";
import {
  selectBuildingFilter,
  selectAccessToken,
  selectFilteredWorkstationsList,
  selectSiteFilter,
  selectUserManagementList,
  selectCompanyAndSiteSettings
} from "core/selectors";
import {
  useDeleteWorkstationMutation,
  useWorkstationsByBuildingsQuery
} from "features/workstations/workstationApiSlice";
import { setWorkstationsByBuildings } from "features/workstations/workstationsSlice";
import { useUsersQuery } from "features/userManagement/userManagementApiSlice";
import { setUserManagementList } from "features/userManagement/userManagementSlice";
import { useNeighborhoodsQuery } from "features/neighborhoods/neighborhoodApiSlice";
import { setNeighborhoodsList } from "features/neighborhoods/neighborhoodsSlice";
import {
  getFormattedDate,
  getMappedResponse,
  getMappedWorkstationList,
  getWorkstationStatus
} from "shared/lib/getters";
import { selectCompanyId } from "core/selectors/siteSelectors";
import { isValidAndNotEmptyString } from "shared/lib/validation";
import WorkstationInfoPage from "../WorkstationInfoPage/WorkstationInfoPage";
import WorkstationPage from "../WorkstationPage/WorkstationPage";
import { selectIsUsersWithoutWorkstation } from "core/selectors/userManagementSelectors";
import WorkstationsCurrentStatus from "shared/components/WorkstationsCurrentStatus/WorkstationsCurrentStatus";
import { styled } from "@mui/material";

const DELETE_TITLE = "Delete Workstation";

const getColumns = (userList) => [
  {
    field: "workstationName",
    headerName: "Name",
    editable: false,
    flex: 1
  },
  {
    field: "status",
    headerName: "Status",
    editable: false,
    flex: 2,
    valueGetter: (params) => {
      return getWorkstationStatus(params.row);
    },
    renderCell: (params) => {
      const employee = userList.find((user) => user.id === params.row.assignedEmployeeId);

      const text = employee ? `${params.value} - ${employee.firstName} ${employee.lastName}` : params.value;

      return <TextEllipsis color={getTableStatusColumnColor(params.value)}>{text}</TextEllipsis>;
    }
  },
  {
    field: "assignable",
    headerName: "Auto assigned",
    editable: false,
    flex: 1,
    valueGetter: (params) => {
      const isAssignable = params.row.seatFeatures?.some((feature) => feature === "availableForAutoSeating");

      if (isAssignable) {
        return "Yes";
      } else {
        return "No";
      }
    }
  },
  {
    field: "primary",
    headerName: "Primary",
    editable: false,
    flex: 1,
    valueGetter: (params) => params.row.permanentOwner
  },
  {
    field: "neighborhoodName",
    headerName: "Neighborhood",
    editable: false,
    flex: 1
  },
  {
    field: "privateRoom",
    headerName: "Private Room",
    editable: false,
    flex: 1
  },
  {
    field: "windows",
    headerName: "Windows",
    editable: false,
    flex: 1
  }
];

const mapStateToProps = (state) => ({
  token: selectAccessToken(state),
  site: selectSiteFilter(state),
  building: selectBuildingFilter(state),
  workstationsByFilters: selectFilteredWorkstationsList(state),
  userList: selectUserManagementList(state),
  companyId: selectCompanyId(state),
  isUsersWithoutWorkstation: selectIsUsersWithoutWorkstation(state),
  companyAndSiteSettings: selectCompanyAndSiteSettings(state)
});
const WorkstationListingPage = ({ history }) => {
  /* ------------------ HOOKs ------------------ */

  const dispatch = useDispatch();

  /* ------------------ STATEs ------------------ */

  const [showInfoSidebar, setShowInfoSidebar] = useState(false);
  const [showEditSidebar, setShowEditSidebar] = useState(false);
  const [showAddSidebar, setShowAddSidebar] = useState(false);
  const [infoId, setInfoId] = useState(null);
  const [workstationId, setWorkstationId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const {
    token,
    site,
    building,
    workstationsByFilters,
    userList,
    companyId,
    isUsersWithoutWorkstation,
    companyAndSiteSettings
  } = useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const { data: userData, isLoading: isUserLoading } = useUsersQuery(null, { skip: !token });
  const { data: neighborhoodData, isLoading: isNeighborhoodLoading } = useNeighborhoodsQuery(
    { site },
    { skip: !site || !token }
  );
  const { data: workstationData, isLoading: isWorkstationLoading } = useWorkstationsByBuildingsQuery(
    { site, building, date: getFormattedDate(new Date(), "yyyy-MM-dd") },
    { skip: !token || !site || !building }
  );

  const [deleteWorkstation, { error, isSuccess }] = useDeleteWorkstationMutation();

  /* ------------------ MEMOs ------------------ */

  const isMapEnabled = useMemo(() => companyAndSiteSettings.is_room_map_enabled, [companyAndSiteSettings]);

  const columns = useMemo(() => getColumns(userList), [userList]);

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (isSuccess) {
      toast.success("Done.");
    }
    if (error) {
      const { message } = error.data;
      if (isValidAndNotEmptyString(message) && message.startsWith("DailySeat with id =")) {
        return toast.error(
          "The workstation you are trying to delete has existing user assignments. Please unassign it from any users by going to 'Workstation Info' > Day > Remove Daily Assignment, and then try deleting again."
        );
      }
      toast.error(error.data.message);
    }
  }, [error, isSuccess]);

  useEffect(() => {
    if (neighborhoodData && !isNeighborhoodLoading) {
      const mappedData = getMappedResponse(neighborhoodData.neighborhoods);
      dispatch(setNeighborhoodsList(mappedData));
    }
  }, [neighborhoodData, isNeighborhoodLoading]);

  useEffect(() => {
    if (userData && !isUserLoading) {
      dispatch(setUserManagementList(userData.employees));
    }
  }, [userData, isUserLoading]);

  useEffect(() => {
    if (workstationData && !isWorkstationLoading) {
      const mappedList = getMappedWorkstationList(workstationData[building], userList);
      dispatch(setWorkstationsByBuildings(mappedList));
    }
  }, [workstationData, isWorkstationLoading]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const onEdit = (id) => {
    setWorkstationId(id);
    setIsEdit(true);
    setShowEditSidebar(true);
  };

  const onInfo = (id) => {
    setInfoId(id);
    setShowInfoSidebar(true);
  };

  const onAdd = () => {
    setIsEdit(false);
    setShowAddSidebar(true);
  };

  const onDelete = async (workstationId) => {
    try {
      const workstation = workstationsByFilters.find((item) => item.id === workstationId);

      if (workstation.ownerId) {
        return toast.error(
          `This room is assigned to ${workstation.permanentOwner}, please remove the permanent assignment using the edit workstation screen and then try deleting again.`
        );
      }

      await deleteWorkstation({
        companyId,
        workstationId,
        site,
        building,
        floor: workstation.floor,
        roomName: workstation.roomName,
        ownerId: workstation.ownerId
      });
    } catch (e) {
      console.error(e);
    }
  };

  /* ------------------ RENDERs ------------------ */

  const deleteWorkstationText = (
    <div>
      <span>If you choose to delete this workstation:</span>
      <ul>
        <li>It will no longer be visible in the BWith app</li>
        <li>Users will not be able to see who is seated here</li>
        <li>BWith will not be able to assign users here</li>
        <li>All attributes such as windows, owner etc. will be deleted</li>
      </ul>
      <div style={{ marginTop: "16px" }}>
        <span>
          Note that in case you don’t want this workstation to be in use you can simply click the edit icon and change
          its ‘Assignable’ state to ‘Not in use’.
        </span>
      </div>
    </div>
  );

  return (
    <>
      <DataTable
        rows={workstationsByFilters}
        columns={columns}
        pageName={WORKSTATION_TABLE_NAME}
        addNewText="Add Workstation"
        onEdit={onEdit}
        onInfo={onInfo}
        onDelete={onDelete}
        onAdd={onAdd}
        deleteTitle={DELETE_TITLE}
        deleteContentText={deleteWorkstationText}
        showFilterButton
        showSiteAndBuilding
        showFloorSelector
        renderToolbarDetailsComponent={() => <WorkstationsCurrentStatus />}
        showMapViewSwitcher
        isMapEnabled={isMapEnabled}
        defaultSort={{ field: "roomName", sort: "asc" }}
        isLoading={isNeighborhoodLoading || isUserLoading || isWorkstationLoading}
        showNoAssignment={isUsersWithoutWorkstation}
      />
      {showInfoSidebar && (
        <WorkstationInfoPage
          history={history}
          isOpen={showInfoSidebar}
          setIsOpen={setShowInfoSidebar}
          workstationId={infoId}
        />
      )}
      {showEditSidebar && (
        <WorkstationPage
          history={history}
          isOpen={showEditSidebar}
          setIsOpen={setShowEditSidebar}
          workstationId={workstationId}
          isEdit={isEdit}
        />
      )}
      {showAddSidebar && (
        <WorkstationPage history={history} isOpen={showAddSidebar} setIsOpen={setShowAddSidebar} isEdit={isEdit} />
      )}
    </>
  );
};

const TextEllipsis = styled(Typography)({
  width: "100%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  textWrap: "nowrap"
});

WorkstationListingPage.propTypes = {
  history: PropTypes.object,
  path: PropTypes.string
};

export default withRouter(WorkstationListingPage);
