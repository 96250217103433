import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectAccessToken,
  selectBuildingFilter,
  selectCompanyAndSiteSettings,
  selectCToken,
  selectDepartmentsFilter,
  selectFloorsFilter
} from "core/selectors";
import { useAttendanceQuery, useUpdateDailyAssignmentMutation } from "features/attendance/attendanceApiSlice";
import moment from "moment";
import currentWeekTableColumns from "../utils/currentWeekTableColumns";
import { fillEmployeesSchedules } from "pages/Attendance/helpers";
import { MOMENT_DATE_FORMAT, WORK_DAYS_COUNT } from "shared/constants";
import { toast } from "react-toastify";
import { selectSiteName } from "core/selectors/profileSelectors";
import { selectCompanyId } from "core/selectors/siteSelectors";

export const START_DATE = moment().startOf("week").format(MOMENT_DATE_FORMAT);
export const END_DATE = moment().endOf("week").format(MOMENT_DATE_FORMAT);

const mapStateToProps = (state) => [
  selectAccessToken(state),
  selectCToken(state),
  selectCompanyAndSiteSettings(state),
  selectBuildingFilter(state),
  selectFloorsFilter(state),
  selectDepartmentsFilter(state),
  selectSiteName(state),
  selectCompanyId(state)
];

export default function useCurrentWeekTabLogic({ setSummary }) {
  /* ------------------ STATEs ------------------ */

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(currentWeekTableColumns(null));
  const [token, cToken, companyAndSiteSettings, building, floors, departments, siteName, companyId] =
    useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const { data: attendanceData, isLoading } = useAttendanceQuery(
    {
      fromDate: START_DATE,
      toDate: END_DATE,
      building,
      departments,
      floors
    },
    { skip: !token }
  );

  const [updateDailyAssignment] = useUpdateDailyAssignmentMutation();

  /* ------------------ HANDLERs ------------------ */

  const handleChangeDayType = async ({ dayType, employeeId, date }, cb) => {
    updateDailyAssignment({
      cToken,
      date,
      employeeId,
      dayType,
      companyId,
      site: siteName,
      seatId: null
    })
      .then((res) => {
        const isSuccess = !res?.error?.data;

        if (!isSuccess) {
          const errorMessage = res.error?.data?.details?.[0]?.errorMessage || "Failed to change day type";

          toast.error(errorMessage);
        } else {
          toast.success("Day type changed successfully");
        }

        cb?.(isSuccess);
      })
      .catch((e) => {
        cb?.(false);

        toast.error("Failed to change day type");

        console.error(e);
      });
  };

  const setOfficeDaysColumnHeaders = (data) => {
    if (!data) return;

    const firstTableItem = data[0];

    setColumns(currentWeekTableColumns(firstTableItem, handleChangeDayType));
  };

  const getAttendanceHandler = () => {
    const data = fillEmployeesSchedules(attendanceData.data, {
      startDate: START_DATE,
      endDate: END_DATE,
      sundayFirst: companyAndSiteSettings.first_day_of_week === "Sunday",
      addWeekends: false
    });

    setOfficeDaysColumnHeaders(data);
    setRows(data);
  };

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (!attendanceData || isLoading) return;

    getAttendanceHandler();
  }, [attendanceData, isLoading]);

  useEffect(() => {
    setSummary({
      users: rows?.length,
      days: WORK_DAYS_COUNT
    });
  }, [rows?.length]);

  return {
    columns,
    rows,
    isLoading
  };
}
