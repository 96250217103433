import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, Box, createFilterOptions, Grid, Skeleton, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactHeight } from "react-height";
import TrashIcon from "shared/assets/icons/trash.svg";
import CustomizedSelect from "../Select/CustomizedSelectDeprecated";
import CheckIcon from "shared/assets/icons/check.svg";
import ResolveButton from "shared/components/Buttons/ResolveButton/ResolveButton";
import {
  WeekPlanContainer,
  Day,
  Row,
  Room,
  CustomizedCollapse,
  CustomizedBadge,
  RemoveButton,
  CustomizedTextField,
  CustomIcon,
  AutocompleteItem,
  AutocompleteItemText,
  AutocompleteItemIcons
} from "./style/weekPlanStyle";
import useWeekPlanLogic from "./hooks/useWeekPlanLogic";
import { format } from "date-fns";

const GREEN_COLOR = "#108000";

const filterOptions = createFilterOptions({
  stringify: (option) => option.value
});

const userStatuses = [
  { option: "Arrived", value: "Arrived" },
  { option: "Office", value: "Office" },
  { option: "Home", value: "Home" },
  { option: "Travel", value: "Travel" },
  { option: "Sick", value: "Sick" },
  { option: "Off", value: "Off" }
];

const WeekPlan = ({
  details,
  index,
  selectedIndex,
  setSelectedIndex,
  handleChangeDayType,
  selectedRooms,
  onRemoveAssignmentClick,
  dayTypes,
  isLoading,
  disabled,
  ...props
}) => {
  const {
    setContainerHeight,
    getMonthAndDate,
    getRoomColor,
    getDayTypeText,
    handleExpandClick,
    containerHeight,
    getDayName,
    currentDayData,
    currentRoomData,
    workstationsByBuilding,
    onSelectWorkstation,
    onApplyDailyAssignment,
    isWorkstationByBuildingsLoading,
    isApplyDisabled
  } = useWeekPlanLogic({ details, selectedIndex, setSelectedIndex, selectedRooms, dayTypes, ...props });

  const renderAutocompleteItem = (optionProps, option) => {
    const selected = optionProps["aria-selected"];

    return (
      <AutocompleteItem {...optionProps} key={option.id} selected={selected}>
        {selected ? <AutocompleteItemIcons src={CheckIcon} /> : null}

        <AutocompleteItemText selected={selected}>{option.value}</AutocompleteItemText>
      </AutocompleteItem>
    );
  };

  return (
    <ReactHeight onHeightReady={(height) => setContainerHeight(height)}>
      <WeekPlanContainer expanded={selectedIndex === index}>
        {!isLoading ? (
          <Day selected={selectedIndex === index}>
            <Typography color={selectedIndex === index ? "white" : "#00203E"} fontWeight="400" variant="body1">
              {format(new Date(details.date), "EEEE")?.toUpperCase()}
            </Typography>
          </Day>
        ) : (
          <Skeleton width="100%" height="20px" />
        )}
        {!isLoading ? (
          <>
            <Box>
              <Typography color="#8D9096" fontSize="small" variant="body1">
                {getMonthAndDate(details.date)}
              </Typography>
            </Box>
            <Box height={"58px"}>
              <Room color={getRoomColor(details.workstationName)}>
                <CustomizedBadge
                  color="success"
                  variant="dot"
                  invisible={getRoomColor(details.workstationName) !== GREEN_COLOR}
                >
                  <Typography textAlign="center" variant="body1">
                    {details.workstationName ? `Room ${details.workstationName}` : getDayTypeText(details.dayType)}
                  </Typography>
                </CustomizedBadge>
              </Room>
            </Box>
            <IconButton onClick={() => handleExpandClick(index)}>
              {index === selectedIndex ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </>
        ) : (
          <Skeleton width="100%" height={"88px"} />
        )}
      </WeekPlanContainer>
      <CustomizedCollapse
        in={selectedIndex === index}
        timeout={{ appear: "100", enter: "100", exit: "150" }}
        easing={{ enter: "200ms ease", exit: "150ms ease" }}
        unmountOnExit
        collapsedSize="500px"
        orientation="horizontal"
        containerHeight={containerHeight}
      >
        <Box p={2}>
          <Typography color="#6B6C72">{getDayName(details.date)}</Typography>
          {details?.seatId ? (
            <>
              <Grid container width="100%" alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="h6">{`Change this day's assignment`}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <RemoveButton onClick={() => onRemoveAssignmentClick(details.date)} disabled={isLoading}>
                    <CustomIcon src={TrashIcon} disabled={isLoading} />
                    <Typography>Remove daily assignment</Typography>
                  </RemoveButton>
                </Grid>
              </Grid>
            </>
          ) : currentDayData?.type?.toLowerCase() === "office" ? (
            <Grid container alignItems="center" mt={1}>
              <Grid item xs={5}>
                <Typography variant="h6">{"Assign a daily room"}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Autocomplete
                  disablePortal
                  forcePopupIcon={false}
                  options={workstationsByBuilding}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => (option.value ? option.value : "")}
                  getOptionDisabled={(option) => option?.disabled}
                  onChange={onSelectWorkstation}
                  size="small"
                  value={currentRoomData?.item}
                  fullWidth
                  loading={isWorkstationByBuildingsLoading}
                  disabled={currentDayData?.type !== "Office" || disabled}
                  renderOption={renderAutocompleteItem}
                  renderInput={(params) => <CustomizedTextField {...params} label="" placeholder={"Search room"} />}
                />
              </Grid>
            </Grid>
          ) : null}
          <Grid container alignItems="center" mt={1}>
            <Grid item xs={5}>
              <Typography variant="h6">Change day type</Typography>
            </Grid>
            <Grid item xs={7}>
              <Row>
                <CustomizedSelect
                  handleChange={(e) => handleChangeDayType(e, details.date)}
                  values={userStatuses}
                  selectedValue={[currentDayData?.type || ""]}
                  fullWidth
                  disabled={currentRoomData?.item || details.seatId}
                  placeholder="Select day type"
                  isMultiple={false}
                />

                <ResolveButton
                  text="Apply"
                  variant={isApplyDisabled || disabled ? "outlined" : "contained"}
                  onClick={onApplyDailyAssignment}
                  disabled={isApplyDisabled || disabled}
                />
              </Row>
            </Grid>
          </Grid>
        </Box>
      </CustomizedCollapse>
    </ReactHeight>
  );
};

WeekPlan.propTypes = {
  details: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selectedIndex: PropTypes.string.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  selectedRooms: PropTypes.array,
  handleChangeRoom: PropTypes.func,
  handleChangeDayType: PropTypes.func,
  handleApplySelection: PropTypes.func,
  onRemoveAssignmentClick: PropTypes.func,
  dayTypes: PropTypes.array,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default React.memo(WeekPlan);
