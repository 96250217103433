import { ARRIVED } from "shared/constants";
import { isValidAndNotEmptyString } from "./validation";

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getTableStatusColumnColor = (status) => {
  if (!isValidAndNotEmptyString(status)) return "#00203E";

  switch (status) {
    case "Available": {
      return "#1ED400";
    }
    case "office": {
      return "#00B4F0";
    }
    case "home": {
      return "#8D9096";
    }
    case "day off": {
      return "#9FA1F9";
    }
    case "In use":
    case ARRIVED: {
      return "#108000";
    }
    case "sick": {
      return "#FF7171";
    }
    case "travel": {
      return "#D169A9";
    }
    case "occupied": {
      return "#D52B1E";
    }
    case "Reserved": {
      return "#007399";
    }
    case "Vacant": {
      return "#8D9096";
    }
    default: {
      return "#00203E";
    }
  }
};
